import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocaleData } from "@contentstack/management/types/stack/locale";
import { EntryDataExtends, EntrySelectionGroup } from "../../../../common/utils";

interface ProjectDataState {
  Title: string | null;
  Comment: string | null;
  SelectLanguage: LocaleData | null;
  TargetLanguage: LocaleData[];
  ExportReference: boolean;
  Entries: EntryDataExtends[];
  EntriesUID: string[];
  Errors: {
    Title: boolean;
    Source: boolean;
    Target: boolean;
    Entries: boolean;
  };
  Clean: boolean;

  EntrySelectGroup?: EntrySelectionGroup;
}

const initialState: ProjectDataState = {
  Title: "",
  Comment: "",
  SelectLanguage: null,
  TargetLanguage: [],
  Entries: [],
  EntriesUID: [],
  ExportReference: false,
  Errors: {
    Title: false,
    Source: false,
    Target: false,
    Entries: false,
  },
  Clean: true,
};

export const QuoteDataSlices = createSlice({
  name: "QuoteData",
  initialState,
  reducers: {
    SetSelectLanguage: (state, action: PayloadAction<LocaleData>) => {
      state.SelectLanguage = action.payload;
    },
    SetTargetLanguage: (state, action: PayloadAction<LocaleData[]>) => {
      state.TargetLanguage = action.payload;
    },
    SetEntrySelectGroup: (state, action: PayloadAction<EntrySelectionGroup>) => {
      state.EntrySelectGroup = action.payload;
    },
    SetTitle: (state, action: PayloadAction<string>) => {
      state.Title = action.payload;
    },
    SetExportReference: (state, action: PayloadAction<boolean>) => {
      state.ExportReference = action.payload;
    },
    SetComment: (state, action: PayloadAction<string>) => {
      state.Comment = action.payload;
    },
    SetSelectedEntries: (state, action: PayloadAction<EntryDataExtends[]>) => {
      state.Entries = action.payload;
    },
    SetSelectedEntriesUID: (state, action: PayloadAction<string[]>) => {
      state.EntriesUID = action.payload;
    },
    ResetQuote: (state) => {
      return initialState;
    },
    ResetQuoteEntry: (state) => {
      state.Comment = null;
      state.ExportReference = false;
      state.TargetLanguage = [];
      state.Errors.Title = false;
      state.Errors.Source = false;
      state.Errors.Target = false;
      state.Errors.Entries = false;
    },
    CheckErrors: (state) => {
      state.Errors.Entries = state.EntriesUID.length === 0;
      state.Errors.Title = !state.Title || state.Title.trim() === "";
      state.Errors.Source = !state.SelectLanguage;
      state.Errors.Target = !state.TargetLanguage || state.TargetLanguage.length === 0;
    },
  },
});

export const {
  SetSelectLanguage,
  SetTargetLanguage,
  SetExportReference,
  SetTitle,
  SetEntrySelectGroup,
  SetComment,
  SetSelectedEntries,
  SetSelectedEntriesUID,
  ResetQuote,
  ResetQuoteEntry,
  CheckErrors,
} = QuoteDataSlices.actions;
