export const getServerConfig = async (
  data: ServerModel,
  url: string,
  apiKey: string
): Promise<ServerModel | undefined> => {
  try {
    const response: Response = await fetch(url + "/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": apiKey,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error("Error Saving ServerConfiguration");
    }

    const resp = (await response.json()) as ServerModel;

    return resp;
  } catch (error) {
    console.error("Error Saving ServerConfiguration:", error);
  }
};

export const saveServerConfig = async (data: ServerModel, url: string, apiKey: string): Promise<void> => {
  try {
    const response: Response = await fetch(url + "/Create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": apiKey,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error("Error Saving ServerConfiguration");
    }
  } catch (error) {
    console.error("Error Saving ServerConfiguration:", error);
  }
};

export interface ServerModel {
  servers: Array<ServerData>;
  api: string;
  org_uid: string;
}

export interface ServerData {
  id:number;
  name:string;
  secretKey: string;
  authToken: string;
  url: string;
}
