import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProjectDataState {
  open: boolean;
  type: string;
  projectName: string;
  projectID: number;
}

const initialState: ProjectDataState = {
  open: false,
  type: "",
  projectName: "",
  projectID: 0,
};

export const ConfirmProjectSlices = createSlice({
  name: "ConfirmProject",
  initialState,
  reducers: {
    setConfirmType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    setProjectID: (state, action: PayloadAction<number>) => {
      state.projectID = action.payload;
    },
    setOpenConfirm: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setConfirmType, setProjectName, setProjectID, setOpenConfirm } = ConfirmProjectSlices.actions;
