import { Buffer } from "buffer";

export interface QuoteData {
  createdBy: string;
  exportAction: string;
  exportInfo: any;
  id: number;
  importAction: string;
  lastModifiedBy: string;
  lastModifiedOn: string;
  name: string;
  notes: string;
  packageCount: number;
  portalLink: any;
  sourceLanguage: string;
  sourceLanguageISOCode: string;
  status: string;
  targetLanguages: string[];
}

export class ApiClient {
  headerContentType = "application/json";
  urlBase = "https://api.globalizationpartners.com";
  secretKey = "";
  apiKey = "";

  constructor(secretKey: string, apiKey: string, url: string = "https://api.globalizationpartners.com") {
    this.secretKey = secretKey;
    this.apiKey = apiKey;
    this.urlBase = url;
  }


  createQuote = async (
    title: string,
    notes: string,
    user: string,
    sourceLanguage: string,
    targetLanguages: string[] = []
  ): Promise<QuoteData> => {
    const method = "POST";
    const urlPath = "/quotes";

    // Request Body
    const body = {
      name: title,
      notes: notes,
      createdBy: user,
      sourceLanguage: sourceLanguage,
      targetLanguages: targetLanguages,
    };
    return await this.SendTSCApi(method, urlPath, body);
  };

  saveDocuments = async (
    quoteId: string | number,
    documentId: string,
    content: any,
    contentType: string,
    userName: string,
    sourceLanguage: string,
    lastModified = new Date().toISOString()
  ) => {
    const method = "POST";

    const urlPath = "/quotes/" + quoteId + "/documents";

    const buff = Buffer.from(content);
    const base64data = buff.toString("base64");

    const dateTimeISO = new Date().toISOString();

    // Request Body
    const body = {
      userName,
      documents: [
        {
          documentType: `${contentType}`,
          addedOn: dateTimeISO,
          language: sourceLanguage,
          id: `${documentId}`,
          lastModified: lastModified,
          fileName: `${documentId}.json`,
          title: documentId,
          contents: base64data,
        },
      ],
    };

    return await this.SendTSCApi(method, urlPath, body);
  };

  saveDocumentsFilesURL = async (
    quoteId: string,
    documentId: string,
    contentURL: string,
    contentType: string,
    userEmail: string,
    sourceLanguage: string,
    lastModified = new Date().toISOString()
  ) => {
    const method = "POST";

    const urlPath = "/quotes/" + quoteId + "/documents";

    const base64data = await this.GetBase64FromUrl(contentURL);

    const dateTimeISO = new Date().toISOString();

    // Request Body
    const body = {
      userName: userEmail,
      documents: [
        {
          documentType: `${contentType}`,
          addedOn: dateTimeISO,
          language: sourceLanguage,
          id: `${documentId}`,
          lastModified: lastModified,
          fileName: `${documentId}.json`,
          title: documentId,
          contents: base64data,
        },
      ],
    };

    return await this.SendTSCApi(method, urlPath, body);
  };

  GetBase64FromUrl = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  submitQuote = async (quoteId: string | number, userName: string) => {
    const method = "POST";
    const urlPath = "/quotes/submit";

    // Request Body
    const body = {
      quoteId: quoteId,
      userName,
    };

    // Request
    return await this.SendTSCApi(method, urlPath, body);
  };

  sendSingleItemForTranslation = (
    id: string,
    contentType: string,
    fields: string[] = [],
    user: any,
    sourceLanguage: string,
    targetLanguages: string[] = []
  ) => {
    let quoteId = 0;
    const title = `${contentType} - ${id} - ${new Date().getFullYear()}-${new Date().getMonth()}-${
      new Date().getDay() < 10 ? "0" : ""
    }${new Date().getDay()}`;

    const notes = `Request for quote submitted through GPI Translation Services Connector for Contentful.<br />User E-mail: ${user.email}`;

    return this.createQuote(title, notes, user, sourceLanguage, targetLanguages)
      .then((result: QuoteData) => {
        quoteId = result.id;
        return this.saveDocuments(
          quoteId,
          id,
          JSON.stringify(fields, null, "\t"),
          contentType,
          user,
          sourceLanguage,
          ""
        );
      })
      .then((result) => {
        return this.submitQuote(quoteId, user);
      });
  };

  sendMultipleItemsForTranslation = (
    title: string,
    notes: string,
    items: any[] = [],
    user: any,
    sourceLanguage: string,
    targetLanguages = []
  ) => {
    let quoteId = 0;

    return this.createQuote(title, notes, user, sourceLanguage, targetLanguages)
      .then((result: QuoteData) => {
        quoteId = result.id;
        const promises: Promise<any>[] = [];
        items.forEach((item, i) => {
          const promise = this.saveDocuments(
            quoteId,
            item.id,
            JSON.stringify(item.fields, null, "\t"),
            item.contentType,
            user,
            sourceLanguage,
            ""
          );
          promises.push(promise);
        });

        return Promise.all(promises);
      })
      .then((result) => {
        return this.submitQuote(quoteId, user);
      });
  };

  getQuote = async (quoteId: string | number) => {
    const urlPath = "/quotes/" + quoteId;
    const method = "GET";

    return await this.SendTSCApi(method, urlPath);
  };

  getQuotes = async () => {
    const method = "GET";
    const urlPath = "/quotes";

    return await this.SendTSCApi(method, urlPath);
  };

  getDocuments = async (quoteId: string | number, requestId: string | number) => {
    const method = "GET";
    const urlPath = `/quotes/${quoteId}/documents/${requestId}`;

    return await this.SendTSCApi(method, urlPath);
  };

  closeProject = async (projectId: string | number) => {
    const method = "PUT";
    const urlPath = `/quotes/${projectId}/close`;

    return await this.SendTSCApi(method, urlPath);
  };

  GPIDateString = () => {
    const dateString = new Date().toString().split("(")[0].trim();
    return [dateString.slice(0, dateString.length - 2), ":", dateString.slice(dateString.length - 2)].join("");
  };

  CreateSignature = async (method: string, urlPath: string, GPIDate: string) => {
    let canonicalMessage = method + "\n";
    canonicalMessage += "\n";
    canonicalMessage += this.headerContentType;
    canonicalMessage += "\n";
    canonicalMessage += "\n";
    canonicalMessage += "x-gpi-api-key:" + this.apiKey + "\n";
    canonicalMessage += "x-gpi-date:" + GPIDate + "\n";
    canonicalMessage += urlPath;

    const encoder = new TextEncoder();

    const importKey = await crypto.subtle.importKey(
      "raw",
      encoder.encode(this.secretKey),
      { name: "HMAC", hash: { name: "SHA-256" } },
      false,
      ["sign", "verify"]
    );

    const signature = await crypto.subtle.sign("HMAC", importKey, encoder.encode(canonicalMessage));

    const signatureArray = Array.from(new Uint8Array(signature));
    return btoa(String.fromCharCode.apply(null, signatureArray));
  };

  CreateHeader = async (urlPath: string, method: string) => {
    const GPIDate = this.GPIDateString();

    const signature = await this.CreateSignature(method, urlPath, GPIDate);

    const headers = new Headers();
    headers.append("X-GPI-API-KEY", this.apiKey);
    headers.append("X-GPI-DATE", GPIDate);
    headers.append("Authorization", "GPI-HMAC " + signature);
    headers.append("Content-Type", this.headerContentType);

    return headers;
  };

  FetchData = async (urlPath: string, method: string, headers: Headers, body = null) => {
    let obj = {};
    if (body) {
      obj = {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
      };
    } else {
      obj = {
        method: method,
        headers: headers,
      };
    }

    const resp = await fetch(this.urlBase + urlPath, obj);

    if (!resp.ok) {
      throw new Error(resp.statusText);
    }

    const data = await resp.clone().text();

    if (!data && data === "") return data;

    return JSON.parse(data);
  };

  SendTSCApi = async (method: string, urlPath: string, body: any = null) => {
    // Request Headers
    const headers = await this.CreateHeader(urlPath, method);
    // Request
    return await this.FetchData(urlPath, method, headers, body);
  };
}
