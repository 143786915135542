import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { AppDataSlices } from "./slides/AppData/AppDataSlices";
import { ConfirmProjectSlices } from "./slides/ConfirmProject/ConfirmProjectSlices";
import { ProjectsDataSlices } from "./slides/ProjectsData/ProjectsDataSlices";
import { QuoteDataSlices } from "./slides/Quote/QuoteDataSlices";
import { RequestDataSlices } from "./slides/RequestData/RequestDataSlices";
import { SnackbarSlice } from "./slides/Notification/SnackbarSlice";

const rootReducer = combineReducers({
  AppData: AppDataSlices.reducer,
  ConfirmProject: ConfirmProjectSlices.reducer,
  ProjectsData: ProjectsDataSlices.reducer,
  QuoteData: QuoteDataSlices.reducer,
  RequestData: RequestDataSlices.reducer,
  Snackbar: SnackbarSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type ReduxDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
