import { useCallback, useEffect, useState } from "react";
import { useAppLocation } from "../hooks/useAppLocation";
import { isEmpty } from "lodash";
import { AppConfigurationExtensionContext, InstallationData } from "../contexts/appConfigurationExtensionContext";
import { ServerData, ServerModel, saveServerConfig } from "../utils";

export const AppConfigurationExtensionProvider = ({ children }: any) => {
  const [installationData, setInstallation] = useState<InstallationData>({
    configuration: {},
    serverConfiguration: {},
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { location } = useAppLocation();

  useEffect(() => {
    if (!isEmpty(installationData)) return;
    setLoading(true);
    location.installation
      .getInstallationData()
      .then((data: InstallationData) => {
        setInstallation(data);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }, [installationData, location, setLoading, setInstallation]);

  const setInstallationData = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true);

      const newInstallationData: InstallationData = {
        configuration: { ...installationData.configuration, ...data.configuration },
        serverConfiguration: { ...installationData.serverConfiguration, ...data.serverConfiguration },
      };
      await location.installation.setInstallationData(newInstallationData);
      setInstallation(newInstallationData);


      const api = location.stack._data.api_key;
      const org_uid = location.stack._data.org_uid;
      const servers = [...(newInstallationData.serverConfiguration.servers) as ServerData[]];

      const serverConfigs = {api, org_uid,servers} as ServerModel

      await saveServerConfig(serverConfigs,process.env.REACT_APP_SERVERCONFIGURL!,process.env.REACT_APP_APIKEY!);
      setLoading(false);
    },
    [installationData.configuration, installationData.serverConfiguration, location]
  );


  return (
    <AppConfigurationExtensionContext.Provider value={{ installationData, setInstallationData, loading }}>
      {children}
    </AppConfigurationExtensionContext.Provider>
  );
};
