import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { LocaleData } from "@contentstack/management/types/stack/locale";
import { ContentTypeData } from "@contentstack/management/types/stack/contentType";
import Extension from "@contentstack/app-sdk/dist/src/extension";
import { ServerData } from "../../../../common/utils";

export interface AppDataState {
  locales: LocaleData[];
  defaultCulture?: LocaleData;
  username?: string;
  contentTypes?: ContentTypeData[];
  appSdk: Extension | null;
  servers: Array<ServerData>;
  serverData?: ServerData;
}

const initialState: AppDataState = {
  appSdk: null,
  serverData: undefined,
  defaultCulture: undefined,
  servers: [],
  locales: [],
};

export const AppDataSlices = createSlice({
  name: "AppData",
  initialState: initialState,
  reducers: {
    setServers: (state, action: PayloadAction<Array<ServerData>>) => {
      state.servers = action.payload;
    },

    setServerData: (state, action: PayloadAction<ServerData>) => {
      state.serverData = action.payload;
    },

    setLocales: (state, action: PayloadAction<LocaleData[]>) => {
      if (action.payload.length === 0) return;

      state.locales = action.payload;

      const defaultCode = action.payload[0].fallback_locale
        ? action.payload[0].fallback_locale
        : action.payload[0].code;

      state.defaultCulture = action.payload.find((x) => x.code === defaultCode);
    },

    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },

    setAppSdk: (state, action: PayloadAction<Extension>) => {
      state.appSdk = action.payload;
    },
    setContentTypes: (state, action: PayloadAction<ContentTypeData[]>) => {
      state.contentTypes = action.payload;
    },
  },
});

export const { setServers, setServerData, setUsername, setLocales, setAppSdk, setContentTypes } =
  AppDataSlices.actions;

export default AppDataSlices.reducer;
export const userDataSelector = (state: RootState) => state.AppData;
