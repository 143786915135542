import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TableDataQuotes } from "../../../../common/utils";

interface ProjectDataState {
  isLoadingQuotes: boolean;
  isLoadingProjects: boolean;
  quotes: TableDataQuotes[];
  projects: TableDataQuotes[];
}

const initialState: ProjectDataState = {
  isLoadingQuotes: true,
  isLoadingProjects: true,
  quotes: [],
  projects: [],
};

export const ProjectsDataSlices = createSlice({
  name: "ClientService",
  initialState,
  reducers: {
    setLoadingQuotes: (state, action: PayloadAction<boolean>) => {
      state.isLoadingQuotes = !!action.payload;
    },
    setLoadingProjects: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProjects = !!action.payload;
    },
    setQuotes: (state, action: PayloadAction<TableDataQuotes[]>) => {
      state.isLoadingQuotes = false;
      state.quotes = action.payload;
    },
    setProjects: (state, action: PayloadAction<TableDataQuotes[]>) => {
      state.isLoadingProjects = false;
      state.projects = action.payload;
    },
  },
});

export const { setLoadingQuotes, setLoadingProjects, setQuotes, setProjects } = ProjectsDataSlices.actions;

export default ProjectsDataSlices.reducer;
