import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export interface NotificationOption {
  key: SnackbarKey;
  message: SnackbarMessage;
  options: OptionsObject;
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  }
  dismissed?: boolean;
}

interface SnackbarState {
  notifications: NotificationOption[];
}

const initialState: SnackbarState = {
  notifications: [],
};

export const SnackbarSlice = createSlice({
  name: "Snackbar",
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications = [payload];
      },
      prepare(notification) {
        const payload: NotificationOption = {
          ...notification,
          key: new Date().getTime() + Math.random(),
        };
        return { payload };
      },
    },
    closeSnackbar: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss = payload.dismissAll || notification.key === payload.key;
          return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
        });
      },
      prepare: (key: SnackbarKey) => ({ payload: { key, dismissAll: !key } }),
    },
    enqueueInfoPermanent: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications.push(payload);
      },
      prepare({ key, message }: { key: SnackbarKey; message: SnackbarMessage }) {
        const payload: NotificationOption = {
          key: key,
          message: message,
          options: {
            key: key,
            variant: "info",
            persist: true,
          },
        };
        return { payload };
      },
    },
    enqueueInfo: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications.push(payload);
      },
      prepare(message: SnackbarMessage) {
        const key = new Date().getTime() + Math.random();

        const payload: NotificationOption = {
          key: key,
          message: message,
          options: {
            key: key,
            variant: "info",
          },
        };
        return { payload };
      },
    },
    enqueueSuccess: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications.push(payload);
      },
      prepare(data: { key: SnackbarKey; message: SnackbarMessage }) {
        const payload: NotificationOption = {
          key: data.key,
          message: data.message,
          options: {
            key: data.key,
            variant: "success",
          },
        };
        return { payload };
      },
    },
    enqueueWarning: {
      reducer: (state, { payload }) => {
        state.notifications.push(payload);
      },
      prepare(data) {
        const payload = {
          key: new Date().getTime() + Math.random(),
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            autoHideDuration: 10000,
          },
        };
        return { payload };
      },
    },
    enqueueError: {
      reducer: (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.notifications.push(payload);
      },
      prepare(message: SnackbarMessage) {
        const key = new Date().getTime() + Math.random();

        const payload: NotificationOption = {
          key: key,
          message: message,
          options: {
            key: key,
            variant: "error",
          },
        };
        return { payload };
      },
    },
    removeSnackbar: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.notifications = state.notifications.filter((notification) => notification.key !== payload);
    },
  },
});

export const {
  closeSnackbar,
  enqueueError,
  enqueueInfo,
  enqueueInfoPermanent,
  enqueueSnackbar,
  enqueueSuccess,
  enqueueWarning,
  removeSnackbar,
} = SnackbarSlice.actions;

export default SnackbarSlice.reducer;
