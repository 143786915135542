import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContentTypeData } from "@contentstack/management/types/stack/contentType";
import { EntryDataExtends } from "../../../../common/utils";

interface RequestDataState {
  isLoadingEntries: boolean;
  selectedContentType?: ContentTypeData;
  entries: EntryDataExtends[];
}

const initialState: RequestDataState = {
  isLoadingEntries: false,
  selectedContentType: undefined,
  entries: [],
};

export const RequestDataSlices = createSlice({
  name: "RequestData",
  initialState,
  reducers: {
    setLoadingEntriesList: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEntries = !!action.payload;
    },
    setSelectedContentType: (state, action: PayloadAction<ContentTypeData>) => {
      state.isLoadingEntries = false;
      state.selectedContentType = action.payload;
    },

    setEntries: (state, action: PayloadAction<EntryDataExtends[]>) => {
      state.entries = action.payload;
    },

    resetRequestData(state) {
      return initialState;
    },
  },
});

export const { setLoadingEntriesList, setSelectedContentType, setEntries, resetRequestData } =
  RequestDataSlices.actions;

export default RequestDataSlices.reducer;
